import { Link } from "@StarberryUtils";
import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
// Images
// import ServiceImgLarge from "../../../images/home/service_img_large.jpg"
// import ServiceImgMedium from "../../../images/home/service_img_medium.jpg"
// import ServiceImgSmall from "../../../images/home/service_img.jpg"
import { GetURL } from "../../Common/site/functions";
import ServiceItem from './_item';
// Style
import './OurServices.scss';

import { inViewOptions, easeFunc, containerVariants, fadeInFromRight } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const contentVariants = {
    hidden: {
        opacity: 0,
        x: -50,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ...easeFunc,
            duration: 1.2
        }
    }
}

const contentItemText = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            ...easeFunc,
            duration: 1.2
        }
    }
}

const MotionContainer = motion(Container)

const OurServices = (props) => {

    let url = ''
    if (props.CTA_Link) {
        url = GetURL(props.CTA_Link.id)
    }

    return (
        <div className="service-section">
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <MotionContainer
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Row>
                            <Col lg="12">
                                <div className="service-heading">
                                    <motion.span variants={contentVariants}>{props.Title}</motion.span>
                                    <motion.h2 variants={contentVariants}>{props.Sub_Title}</motion.h2>
                                </div>
                            </Col>
                        </Row>
                    </MotionContainer>
                )}
            </InView>
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.div
                        className="service-wrap"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row>
                                <Col lg="5" className="order-lg-1 img-zoom pl-0">
                                    <motion.div
                                        className="property-banner-img"
                                        variants={fadeInFromRight}
                                    >
                                        {props.lazyLoad &&
                                            <picture>
                                                <source media="(min-width:992px)" srcSet={props.Tile_Image?.url} />
                                                <source media="(min-width:768px)" srcSet={props.Tile_Image?.url} />
                                                <img className="" src={props.Tile_Image?.url} alt={props.Tile_Image?.alternativeText} />
                                            </picture>
                                        }
                                    </motion.div>
                                </Col>
                                <Col lg="6" className="order-lg-2 ml-lg-auto services_list_block">
                                    <div className="services_block">
                                        <motion.p className="services_text" variants={contentItemText}>{props.Top_Content}</motion.p>
                                        {props.lazyLoad && 
                                            <div className="services_list">
                                                <ul>
                                                    {props.Content_with_Image && props.Content_with_Image.map((content, i) => (
                                                        <ServiceItem
                                                            key={i}
                                                            customDelay={i}
                                                            content={content}
                                                            ctaLink={props?.CTA_Link}
                                                        />
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                        {props.Bottom_Content && <motion.p className="services_text mt-3 mb-4" variants={contentItemText}>{props.Bottom_Content}</motion.p>}
                                        {props.CTA_Label && <motion.div className="btn-space" variants={contentItemText}>
                                            {props.CTA_Link && url[1] == 'Internal' ?
                                                <Link to={`/${url[0]}`} className="btn btn-outline">{props.CTA_Label}</Link> :
                                                <a href={`${url ? url[0] : ''}`} target="_blank" className="btn btn-outline">{props.CTA_Label}</a>
                                            }
                                        </motion.div>}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </motion.div>
                )}
            </InView>
        </div>

    )
}



export default OurServices