import * as React from "react"
import Home from './Home/Home'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
const IndexPage = () => {
    return (
        <Home />
    )
}

export default IndexPage;
