import React from "react"
import { Link } from "@StarberryUtils";
import { GetURL } from "../../Common/site/functions";

import { delayItems } from '../../Utils/animations';
import { motion } from "framer-motion";

const ServiceItem = (props) => {
    const {
        content,
        ctaLink,
        customDelay
    } = props

    let url_1 = ''
    if (content.CTA_Link) {
        url_1 = GetURL(content.CTA_Link.id)
    }

    return (
        <motion.li
            className={`${content.Title_as_Link ? "li-title-only" : "my-4"}`}
            custom={customDelay}
            variants={delayItems}
        >
            <div className="icon-box">
                {content.Title_as_Link ?
                    <React.Fragment>
                        {ctaLink && url_1[1] == 'Internal' ?
                            <Link to={`/${url_1[0]}`}><img src={content.Icon.url} alt={content.Icon?.alternativeText} /></Link> :
                            <a href={`${url_1 ? url_1[0] : ''}`} target="_blank"><img src={content.Icon.url} alt={content.Icon?.alternativeText} /></a>
                        }
                    </React.Fragment>
                    : 
                    <img src={content.Icon.url} alt={content.Icon?.alternativeText} />
                }
            </div>
            <div className="services_name">
                {content.Title_as_Link ?
                    <React.Fragment>
                        {ctaLink && url_1[1] == 'Internal' ?
                            <Link to={`/${url_1[0]}`}><h3>{content.Title}</h3></Link> :
                            <a href={`${url_1 ? url_1[0] : ''}`} target="_blank"><h3>{content.Title}</h3></a>
                        }
                    </React.Fragment>
                    : <h3>{content.Title}</h3>}
                {content.Sub_Content && <p>{content.Sub_Content}</p>}
            </div>
        </motion.li>
    )
}

export default ServiceItem