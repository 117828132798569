import React from "react";
import BannerSection from "./Banner/Banner";
import ServiceSection from "./OurServices/OurServices";
import AboutSection from "./AboutSection/AboutSection";
import ReviewsSection from "./Reviews/Reviews";
import NewSection from "./News/News";
import Getproperty from "./Getproperty/Getproperty";

const HomePage =()=> {

    return(
        <>
         <BannerSection/>
         <ServiceSection/>      
         <AboutSection/>      
         <ReviewsSection/>      
         <NewSection/>      
         <Getproperty/>      
        </>
    )
}

export default HomePage;