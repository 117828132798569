import React from "react";
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import HomePage from '../../components/Home/HomePage'

const Home =()=> {
    const [state, setState] = React.useState({
        showMenu: false
    })

    const handlerClick = () => { 
        setState({ ...state, showMenu: !state.showMenu })
        var root = document.getElementsByTagName('html')[0];
        if (state.showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }

    }
    return (
        <>
       <div className={state.showMenu ? "wrapper open-search-block" : "wrapperv hhh"}>
         <Header showMenu={state.showMenu} handlerClick={handlerClick} />
        <HomePage />
        <Footer />
        </div>
        </>
    )
}

export default Home;